<template>
  <div class="shopDetail_page">
    <div class="container flex align-items-center justify-content-between">
      <!-- 店铺信息 -->
      <div class="box_shop">
        <div class="flex">
          <div class="avatar">
            <img v-if="shopAvatar!='' && shopAvatar!=null && shopAvatar!='null'" :src="common.setImgLocation(shopAvatar)" alt="" />
            <img v-else-if="shop.user!=''&&shop.user!=null" :src="common.setImgLocation(shop.user.avatar)" alt="" />
            <img v-else src="../../static/logo.png" alt="" />
          </div>
          <div class="info">
            <div class="title" v-if="store!=''&&store!=null">{{ store }} <span class="text">【{{name}}】</span></div>
            <div class="title" v-else-if="shop.user!=''&&shop.user!=null">{{ shop.user.nick_name }} <span class="text">【{{name}}】</span></div>
            <div class="detail_message flex">
              <div class="item"><span>{{ageStr}}</span></div>
              <div class="item" v-if="status == 4 && auth_type != 5">个人实名认证</div>
              <div class="item" v-if="auth_type == 5">企业实名认证</div>
              <div class="item">{{ shop_province }}-{{ shop_city }}</div>
            </div>
            <div class="box_ flex">
              <div class="box_item">
                <div>{{ shop.bond_price ? shop.bond_price : '未缴纳' }}</div>
                <div>消保金</div>
              </div>
              <div class="box_item">
                <div>{{ shop.shops_grade }}</div>
                <div>店铺评分</div>
              </div>
              <div class="box_item">
                <div>{{ shop.fans_number }}</div>
                <div>粉丝</div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-content-between">
            <div class="focus" @click="maskShow = !0">关注</div>
            <div class="shop_desc">{{ shop.desc ? shop.desc : '该店主很懒，什么都没留下' }}</div>
        </div>
      </div>
      <div class="box_remark">
        <div class="box_item">
          <div v-if="shop.is_excellent === 1" class="box_tag">
            <div class="box_icon"><img src="../../static/shop1.png" alt=""></div>
            <div>优质店铺</div>
          </div>
          <div v-if="shop.guarantee ===1 " class="box_tag">
            <div class="box_icon"><img src="../../static/danbao.png" alt=""></div>
            <div>平台担保</div>
          </div>
          <div  class="box_tag" v-if="shop.consignment === 1">
            <div class="box_icon"><img src="../../static/shop2.png" alt=""></div>
            <div>超级店铺</div>
          </div>
          <div  class="box_tag" v-if="shop.is_windward === 1">
            <div class="box_icon"><img src="../../static/shop3.png" alt=""></div>
            <div>白虫店铺</div>
          </div>
        </div>
        <div class="comment">
          <div class="title">店铺评价</div>
          <div class="comment_content">
            <div
              class="comment_item"
              v-for="(item, index) in evaluate"
              :key="index"
            >
              {{item.name + '(' + item.evaluate_shops_count + ')' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 店铺相关商品 -->
    <div class="goods_content">
      <div class="goods_content_title">店铺拍品</div>
      <div class="contents">
        <goodsList :list="list"></goodsList>
         <el-empty v-if="list.length === 0" description="暂无商品"></el-empty>
      </div>
    </div>
     <!-- 遮罩层 -->
    <div v-if="maskShow" class="mask" @click="maskShow = !!0">
      <div class="box_notice">
        <div class="title">中蟋网交易市场</div>
        <div class="box_img">
          <img src="../../static/market.png" alt="">
        </div>
        <div class="text">微信扫描识别二维码进入蟋蟀交易市场</div>
      </div>
    </div>
  </div>
</template>
<script>
import goodsList from "@/components/goodsList";
export default {
  components: { goodsList },
  data() {
    return {
      id: "",
      shop:{},
      shopAvatar: "",
      store: "",
      ageStr: '',
      shop_province: "",
      shop_city: "",
      evaluate: [],
      list:[],
      maskShow:!!0,
      name: '',
      auth_type: '',
      status: ''
    };
  },
  methods: {
    getData() {
      this.$http.ShopDetail(`id=${this.id}`).then((res) => {
        console.log(res, "---ShopDetail");
        this.shop = res.data.shop;
        this.shopAvatar = res.data.shop.cover;
        this.auth_type = res.data.shop.auth_type;
        this.status = res.data.shop.user.status;
        this.name = res.data.shop.shop_level.name;
        this.store = res.data.shop.store;
        this.ageStr = res.data.ageStr;
        this.shop_province = res.data.shop.shop_province;
        this.shop_city = res.data.shop.shop_city;
        this.evaluate = res.data.evaluate_word;
      });
      this.$http.ShopGoods(`id=${this.id}&page=${1}`).then((res) => {
          this.list = res.data.list
        console.log(res, "---ShopGoods");
      });
    },
  },
  mounted() {
    console.log(this.$route.params.id)
    this.id = this.$route.params.id;
    this.getData();
  },
  created () {
    setTimeout(() => {
      document.title = (this.store != '' && this.store != null) ? this.store + '---中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！' : this.shop.user.nick_name + '---中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！'
    }, 1000)
 },
};
</script>
<style lang="scss" scoped>
.shopDetail_page {
  .container {
    width: 100%;
    margin: 50px auto;
    .box_shop {
      width: 750px;
      height: 400px;
    //   background-color: #f5f5f5;
    box-shadow: 0 0 10px #c0c0c0;
      padding: 20px;
      .avatar {
        width: 120px;
        height: 120px;
        margin-top: 30px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .info {
        padding-left: 20px;
        .title {
          font-size: 20px;
          font-weight: 800;
        }
        .text {
          color: red;
        }
        .address {
          padding: 20px 0;
          color: rgb(99, 99, 99);
          font-size: 16px;
        }
        .box_ {
          width: 400px;
          // background-color: pink;
          .box_item {
            height: 70px;
            border-right: 1px solid #ccc;
            text-align: center;
            width: 120px;
            div {
              padding: 5px 0;
            }
          }
          .box_item:nth-last-child(1) {
            border-right: 0px;
          }
        }
      }
    }
    .focus{
        margin-top: 50px;
        margin-left: 25px;
        width: 90px;
        height: 40px;
        background-color: rgb(177, 11, 39);
        border-radius: 5px;
        line-height: 40px;
        font-size: 18px;
        text-align: center;
        color: #fff;
        font-weight: 800;
        cursor: pointer;
    }
    .shop_desc {
       
      width: 100%;
      height: 100px;
      padding: 20px;
       padding-left: 50px;
    }
    .box_remark {
      width: 300px;
      height: 400px;
      border: 1px solid #c0c0c0;
      border-radius: 5px;
      padding: 20px;
      .box_item {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .box_tag {
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        .box_icon {
          width: 40px;
          height: 30px;
          // background-color: pink;
          font-size: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
      .comment {
        margin-top: 10px;
        border-top: 1px solid #c0c0c0;
        .title {
          font-size: 16px;
          font-weight: 800;
          padding: 15px 0;
        }
        .comment_content {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .comment_item {
            width: 120px;
            border-radius: 5px;
            // height: 50px;
            padding: 5px 0;
            text-align: center;
            background-color: rgb(189, 59, 80);
            color: #fff;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .goods_content {
    width: 100%;
    // height: 100px;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
  }
  .goods_content_title {
    padding: 20px;
    font-size: 16px;
    font-weight: 800;
    border-bottom: 1px solid #c0c0c0;
  }
  .contents {
  }
}
.mask {
  width: 100%;
  height: 100%;
  background-color: rgba(104, 104, 104, 0.623);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  .box_notice {
    width: 330px;
    height: 370px;
    background-color: #fff;
    margin: 200px auto;
    overflow: hidden;
    border-radius: 5px;
    .title{
      font-size: 20px;
      font-weight: 800;
      padding-top: 20px;
      text-align: center;
    }
    .box_img{
      width: 200px;
      height: 200px;
      margin: 20px auto;
      background-color: green;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text{
      padding-top: 20px;
      text-align: center;
      font-size: 16px;
      // font-weight: 800;
    }
  }
}
.detail_message {
  margin: 20px 0;
      .item {
        margin-right: 10px;
      }
    }
</style>